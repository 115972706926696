import { Box, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Icon, iconCircleCheck, iconCircleXmarkSolid, iconArrowCircleRight } from "theme/icons";
import { Link, useLocation } from "react-router-dom";
import { ItemTestResultItem } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import { styleTabContent } from "./allTestResults";
import { useAppIntl } from "services/useAppIntl";
import { useTestSettings } from "hooks/useTestSettings";
import { finalTestResult } from "utils/helpers/finalTestResult";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

type Props = {
  testResults: ItemTestResultItem[];
};

export const FinalTest = ({ testResults }: Props) => {
  const theme = useTheme();
  const intl = useAppIntl();
  const location = useLocation();
  const { finalTest } = useTestSettings();
  const { getDateWithLocalTime: getDateWithTime, getDuration } = useDateService();

  return (
    <>
      {testResults
        .map((testResult, i) => {
          const { duration, finished } = testResult;
          const { testPassed, result, total } = finalTestResult(testResult, finalTest);

          const testDuration = duration
            ? getDuration(duration)
            : intl.formatMessage({ id: "test.create.custom.dropdown.duration" });
          const icon = testPassed ? (
            <Icon name={iconCircleCheck} htmlColor={theme.palette.success.main} />
          ) : (
            <Icon name={iconCircleXmarkSolid} htmlColor={theme.palette.error.main} />
          );

          return (
            <Link
              key={i}
              style={{ textDecoration: "none", color: theme.palette.text.primary }}
              to={"final/" + testResult.statementRef}
              state={{ from: location }}
            >
              <Box sx={styleTabContent}>
                <Box display="flex" alignItems={"center"}>
                  <Box display={"flex"} width="140px">
                    {icon}
                    <FormattedTypography
                      marginLeft={"10px"}
                      variant="body2"
                      id={testPassed ? "common.title.passed" : "common.title.failed"}
                    />
                  </Box>
                  <Typography variant="body2" display={"flex"} textAlign={"left"}>
                    {result}/{total}
                  </Typography>
                </Box>
                <Box display={"flex"} textAlign={"right"}>
                  <Box display={"flex"} flexDirection={"column"} mr={2}>
                    <Typography variant="body2">{testDuration}</Typography>
                    <Typography variant="caption">{getDateWithTime(finished)}</Typography>
                  </Box>
                  <IconButton>
                    <Icon name={iconArrowCircleRight} color="primary" />
                  </IconButton>
                </Box>
              </Box>
            </Link>
          );
        })
        .reverse()}
    </>
  );
};
