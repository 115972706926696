import { useSetRecoilState } from "recoil";
import { useCallback } from "react";
import { ExtendedTreeNode, TreeNode } from "types/cds";
import { theoryAreaState, theoryChapterState, theoryState } from "state/theoryState";
import useChapterActivities from "./useChapterActivities";
import { TheoryState } from "services/appStateService";

/**
 * Hook for setting a default theory state (theory|area|chapter).
 */
export const useSetDefaultTheoryState = () => {
  const setChapterState = useSetRecoilState(theoryChapterState);
  const setTheoryState = useSetRecoilState(theoryState);
  const setAreaState = useSetRecoilState(theoryAreaState);
  const { setChapterActivities } = useChapterActivities();

  const setDefaultTheoryState = useCallback(
    (learningStructure: ExtendedTreeNode[], state: TheoryState[] | undefined) => {
      // Set default area and chapter
      let currentArea = learningStructure.find((activity) => activity.parentId === 1);
      let currentChapter: TreeNode | undefined = learningStructure.find(
        (activity) => activity.parentId === currentArea?.id
      );

      // If we have a saved state, get area and chapter from that
      if (state) {
        currentChapter = learningStructure.find(
          (activity) => activity.activityKey?.contentId === state![0].key.contentId
        );
        currentArea = learningStructure.find((activity) => activity.id === currentChapter?.parentId);
      }

      // Also need to set default states
      setTheoryState(learningStructure);
      // TODO: Vi har ett cleanup jobb att göra med dessa modeller som inte alltid mappar
      setAreaState(currentArea as TreeNode);

      if (currentChapter) {
        setChapterState(currentChapter);
        setChapterActivities(currentChapter);
      }

      return currentChapter;
    },
    [setChapterState, setTheoryState, setAreaState, setChapterActivities]
  );

  return { setDefaultTheoryState };
};
