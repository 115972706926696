import { Box, useTheme, Theme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { theoryChapterState } from "state/theoryState";
import { EducationContentTypes, educationContentTypeState } from "state/learningStructureState";
import { useAccount } from "@azure/msal-react";
import { XapiKey } from "services/lrsService";
import { useAssessment } from "hooks/useAssessment";
import { Test, TestLabel } from "layout/test/test";
import { NoContent } from "utils/helpers/theory/NoContent";
import useMediaQuery from "@mui/material/useMediaQuery";

interface TheoryAnswerProps {}
export const TheoryAnswer = (props: TheoryAnswerProps) => {
  const educationContentType: EducationContentTypes = "answer";
  const account = useAccount();
  const chapter = useRecoilValue(theoryChapterState);
  const { assessment } = useAssessment({ key: chapter?.activityKey!, type: educationContentType });
  const theme = useTheme();
  const xapiKey: XapiKey = {
    userId: account!.localAccountId,
    contentId: chapter!.activityKey!.contentId,
    versionId: chapter!.activityKey!.versionId!,
    stateId: useRecoilValue(educationContentTypeState).get(educationContentType)!,
  };
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (!account) {
    console.log("No active account");
    return null;
  }

  if (assessment === undefined) return null;

  if (assessment === null) return <NoContent heading={chapter?.title} />;

  // Start the test
  return (
    <Box mx={isMobile ? theme.spacing(0) : theme.spacing(-2)} mt={isMobile ? theme.spacing(4) : undefined}>
      <Test
        label={TestLabel.Answer}
        assessment={assessment}
        xapiKey={xapiKey}
        userId={account.localAccountId}
        isAnswer
      />
    </Box>
  );
};
