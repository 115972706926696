import { ItemAssessmentGroup, ItemAssessment, ItemType, ItemQuestion } from "@strmediaochitab/optima-component-library";
import { ExtendedTreeNode } from "types/cds";

type ItemAssessmentGroupExtended = Omit<ItemAssessmentGroup, "items"> & { learningObjectiveMap: any[][]; items: any };

const removeDuplicateQuestion = (array: ItemQuestion[], key: "contentId" | "versionId") => {
  const seen = new Set();
  return array.filter((item) => {
    const keyValue = item.id[key];
    if (seen.has(keyValue)) {
      return false;
    } else {
      seen.add(keyValue);
      return true;
    }
  });
};

export const mapToChapter = (structure: ExtendedTreeNode[] | undefined, assessmentDataItem: any) => {
  const assessment: ItemAssessment = assessmentDataItem.items[0];
  const assessmentsGroups: ItemAssessmentGroupExtended[] = assessment.items as ItemAssessmentGroupExtended[];

  const map = new Map<number, string[]>();

  structure?.forEach((treeNode) => {
    if (treeNode.learningObjectives.ids.length > 0) {
      if (!map.has(treeNode.parentId)) map.set(treeNode.parentId, []);

      const current = map.get(treeNode.parentId) ?? [];
      map.set(treeNode.parentId, [...current, ...treeNode.learningObjectives.ids]);
    }
  });

  const newAssessment: ItemAssessment = {
    type: ItemType.Assessment,
    configuration: assessment.configuration,
    items: [],
    referenceKey: assessment.referenceKey,
  };

  if (!assessmentsGroups) return newAssessment;

  const loMap = assessmentsGroups.flatMap((x) => x.learningObjectiveMap);

  map.forEach((value, key) => {
    const foundLoMap = loMap.filter((x) => value.includes(x[0]));

    const items = foundLoMap.flatMap(
      (x) =>
        x[1].map((y: any) => {
          return { type: ItemType.Question, configuration: { type: "Reference" }, id: y };
        }) as ItemQuestion[]
    );
    const itemsUnique = removeDuplicateQuestion(items, "versionId");

    if (process.env.NODE_ENV === "development") console.log("group items", items, itemsUnique);

    if (foundLoMap.length > 0) {
      const itemGroup: ItemAssessmentGroupExtended = {
        type: ItemType.AssessmentGroup,
        configuration: { type: assessmentsGroups[0].configuration.type, quota: value.length },
        items: itemsUnique,
        learningObjectives: value,
        learningObjectiveMap: foundLoMap,
        name: structure?.find((x) => x.id === key)?.title,
        id: structure?.find((x) => x.id === key)?.activity.versionId,
      };

      newAssessment.items.push(itemGroup);
    }
  });

  return newAssessment;
};
