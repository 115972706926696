import { TestSettings } from "hooks/useTestSettings";
import { ItemTestResultItem } from "services/reportingService";

/**
 * Calculates the final test result based on the provided test result and test settings.
 *
 * @param testResult - An object containing the test result item with `value` and `max` properties.
 * @param finalTest - An object containing the test settings with `ratio` and optional `skip` properties.
 * @returns An object containing:
 *   - `testPassed`: A boolean indicating whether the test was passed based on the ratio.
 *   - `result`: The calculated result value, adjusted to be within the valid range.
 *   - `total`: The total possible score after accounting for any skipped items.
 */
export function finalTestResult(testResult: ItemTestResultItem, finalTest: TestSettings) {
  const { value, max } = testResult;

  // Calculate result values
  const testPassed = parseInt(value) >= finalTest.ratio!;
  let result = parseInt(value);
  const total = parseInt(max) - (finalTest.skip || 0);

  if (result < 0) result = 0;
  if (result > total) result = total;

  return { testPassed, result, total };
}
