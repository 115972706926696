import { useEffect } from "react";
import { Location, useLocation } from "react-router-dom";

interface IScrollHandler {
  selector: string;
  delay?: number;
  offsetTop?: number;
}

/**
 * Scrolls to top when the pathname changes.
 */
function ScrollToTop() {
  const { pathname } = useLocation();

  console.log("ScrollToTop: pathname", pathname);

  useEffect(() => {
    // Skip scrolling to top if pathname includes "/read" since we have a custom scroll handler for that
    if (pathname.includes("/read") || pathname.includes("/practice")) return;

    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      // behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

export default ScrollToTop;

/**
 * Scrolls to an element on the page
 * @param selector html element query selector
 * @param delay scroll delay
 * @param offsetTop element scroll top offset
 */
export const scrollToElement = (props: IScrollHandler) => {
  const { selector, delay, offsetTop } = props;

  setTimeout(
    () => {
      const element: HTMLElement | null = selector === "" ? null : document.querySelector(selector);

      window.scrollTo({
        behavior: "smooth",
        top: element ? (offsetTop ? element.offsetTop + offsetTop : element.offsetTop) : 0,
      });
    },
    delay ? delay : 250
  );
};

interface ILocationScrollHandler {
  location: Location;
  delay?: number;
  offsetTop?: number;
}
/**
 * Scrolls to an element on the page based on the location hash in the url. If no hash exist, then will scroll to page top.
 * Example: navigating to the url http://mysite.com/mypage#elementId will scroll to an element on the page with the id 'elementId'
 * @param location react-router location object
 * @param delay scroll delay
 * @param offsetTop element scroll top offset
 */
export const scrollToElementByLocation = (props: ILocationScrollHandler) => {
  const { location, delay, offsetTop } = props;
  const selector = `[id='${location.hash.substring(1)}']`;

  scrollToElement({ selector, delay, offsetTop });
};
